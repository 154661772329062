import React from 'react'
import {  Container, Grid, Typography } from '@mui/material'


import cloudFooter from '../../../../assets/cloud-footer.png'
import bkImg from '../../../../assets/tokenomics-bk.jpg'
import tokenomicsText from '../../../../assets/tokenomics-header.png'
import tokenomicsNoOne from '../../../../assets/tokenomics-no-one.png'
import tokenomicsInfo from '../../../../assets/tokenomics-info.jpg'
import FooterFiireLeft from '../../../../assets/Footer-fiire-left.png'
import FooterFiireRright from '../../../../assets/Footer-fiire-right.png'
import toknomicText from '../../../../assets/toknomic-text.png'
import styled from '@emotion/styled'


const Wrapper = styled.div`
    background-color: #FF1315;
    background-image: url(${FooterFiireLeft}), url(${FooterFiireRright}), url(${bkImg});
    background-size: 200px, 200px, cover;
    background-repeat: no-repeat;
    background-position: left bottom, right bottom, top center;
    padding: 80px 0;
    margin-top: -4%;
    @media (max-width: 1300px) {
        background-size: 300px, 300px, cover;

    }
    @media (max-width: 1069px) {
        background-image: url(${bkImg});
        background-position: top center;
        background-size: cover;
    }
`
const GridEx = styled(Grid)`
  background-image: url(${tokenomicsInfo});
  padding: 50px 0;
  background-size: cover;
`

const CloudFooter = styled.img`
    width: 100%;
    height: auto;
    margin-top: -4%;
    position: relative;
    z-index: 9999999;
`
const TypographyEx = styled(Typography)`
    background-image: url(${toknomicText});    
    background-size: 100%;
    background-repeat: no-repeat;
    width:100%;
    max-width: 350px;
    margin-bottom: 30px;
    padding: 7px 0;
    margin: 10px auto;
    font-family: PoppinsB;
`

const TokenomicsNoOne = styled(CloudFooter)`
  max-width: 110px;
  position: absolute;
  top: 0;
  left: -50px;
  
  @media (max-width: 1285px) {
    left: 0px;
    top: 40px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`
// toknomicText
const Tokenomics = () => {
  return (
    <>
      <CloudFooter src={cloudFooter} alt='clouds' />
      <Wrapper  id="tokenomics">
        <Container>
          <Grid container>
            <Grid xs={12} style={{position: 'relative',marginTop:20}}>
              <img data-aos="zoom-in"
        data-aos-duration="2000" src={tokenomicsText} alt='tokenomicsText' style={{width: '100%',height: 'auto',marginBottom: 20}} />
              <TokenomicsNoOne src={tokenomicsNoOne} alt='tokenomicsText' style={{width: '100%',height: 'auto'}} />
            </Grid>
            
            <GridEx xs={12}>
              <TypographyEx data-aos="fade-up" data-aos-duration="2000"  variant='subtitle1' color="#000000" align='center' fontF fontWeight="bold">Name: Floki V Pepe</TypographyEx>
              <TypographyEx data-aos="fade-up" data-aos-duration="2200"  variant='subtitle1' color="#000000" align='center' fontF fontWeight="bold">Symbol: FLOPE</TypographyEx>
              <TypographyEx data-aos="fade-up" data-aos-duration="2400"  variant='subtitle1' color="#000000" align='center' fontWeight="bold">Supply: 100000000</TypographyEx>
              <TypographyEx data-aos="fade-up" data-aos-duration="2600"  variant='subtitle1' color="#000000" align='center' fontWeight="bold">Buy: 1%</TypographyEx>
              <TypographyEx data-aos="fade-up" data-aos-duration="2800"  variant='subtitle1' color="#000000" align='center' fontWeight="bold">Sell: 1%</TypographyEx>
            </GridEx>
          </Grid>
        </Container>
        </Wrapper>
    </>
  )
}

export default Tokenomics