import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Wrapper = styled.div`
    background-color: #02B9B5;
    border-top: 2px solid #1A1A1A;
    border-bottom: 2px solid #1A1A1A;
    padding: 5px 10px;
    margin-bottom: 20px;
    text-align: center;
    a{
      margin: 0;
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75;
      letter-spacing: 0.00938em;
      text-align: center;
      color: #ffffff;
      font-weight: 700;
      text-decoration: none;
    }
`
const Footer = () => {
  return (
    <Wrapper>
        <Typography variant='subtitle1' color="#ffffff" align='center' fontWeight="bold">Copyright © 2023 FLOPE. All Right Reserved.</Typography>
        <a href='mailto:info@flokivspepe.lol '>info@flokivspepe.lol</a>
    </Wrapper>
  )
}

export default Footer