import React from "react";
import logo from "../../../../assets/Images/logo.png";
import { DesktopMainMenu,  MenuLink } from "../styles";
import { Image } from "../../../Styles/style";
import twitter from "../../../../assets/Images/twitter.png"
import telegram from "../../../../assets/Images/telegram.png"
const DesktopMenu = (props) => {
 
  return (
    <DesktopMainMenu maxWidth="xxl" >
      <MenuLink href="/" p="0px">
        <Image mw="120px" src={logo} alt="Logo" />
      </MenuLink>
      <div style={{display:"flex" , alignItems:"center"}}>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
        <MenuLink p="0 5px 0 10px" href="https://twitter.com/flokivpepe_coin"  target="blank">
          <Image mw="35px" src={twitter} alt="twitter" />
        </MenuLink>
        <MenuLink  p="0 5px"  href="https://t.me/flokivpepe_coin" target="blank">
          <Image  mw="35px"  src={telegram} alt="telegram" />
        </MenuLink>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
