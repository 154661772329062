import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import bg from "../../../../assets/Images/bg.jpg";
import heroimage from "../../../../assets/Images/heroImage.png";
import mobileherobg from "../../../../assets/Images/mobileherobg.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import { AbsoluiteImage } from "../../../Styles/style";

const ContainerWraper = styled(Container)`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* min-height: calc(100vh - 0px); */
  padding: 50px 0px !important;
  overflow: hidden;
  .small {
    display: none;
    @media (max-width: 599px) {
      display: block;
    }
  }
  .large {
    @media (max-width: 599px) {
      display: none;
    }
  }
`;
const GridWraper = styled(Grid)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
  min-height: calc(100vh - 160px);
  @media (max-width: 670px) {
    min-height: calc(78vh - 160px);
  }
  @media (max-width: 599px) {
    min-height: calc(100vh - 160px);
    padding-bottom: 0px;
  }
`;

const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #000000;
  padding: 20px;
  border: 2px solid #000;
  transform: rotate(0deg);
  position: relative;
  ::before {
    content: "";
    display: block;
    background: #ffffff;
    height: 98%;
    width: 98%;
    position: absolute;
    transform: rotate(1deg);
    top: 0%;
    left: 1%;
    z-index: 0;
  }
  margin-top: -10%;
`;
const DIV = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeroSection = () => {
  return (
    <ContainerWraper maxWidth="xxl" id="home">
      <AbsoluiteImage
        t="none"
        l="0"
        b="-2%"
        tran="none"
        // data-aos="zoom-in"
        // data-aos-duration="2000"
        className="large"
        src={heroimage}
      />
      <AbsoluiteImage className="small" src={mobileherobg} />
      <Container>
        <LockerMenu />
        <GridWraper container>
          <GridItem
            data-aos="fade-up"
            data-aos-duration="2000"
            container
            item
            xs={12}
            md={5.5}
          >
            <Paragraph fm="PoppinsSB" color="#000">
              Get ready for an epic crypto clash as Floki faces off against Pepe
              in a groundbreaking showdown!
            </Paragraph>
            <div style={{ display: "flex" }}>
              <CustomButton target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x2ba3d8ecf73937c439726f023d99544c0b48a225&chain=ethereum" data-aos="flip-left" bgc="#18B0FF">Buy Now</CustomButton>
              <CustomButton target="_blank" href="https://www.dextools.io/app/en/ether/pair-explorer/0xc2ab39afab3b761370b6b818c62efdb6d60500f4" data-aos="flip-right"bgc="#FF3848">View Chart</CustomButton>
            </div>
            {/* <DIV> */}
              <Paragraph fm="PoppinsSB" color="#000" m="0">
                CA:
                0xc2ab39afab3b761370b6b818c62efdb6d60500f4
              </Paragraph>
            {/* </DIV> */}
          </GridItem>
        </GridWraper>
      </Container>
      <Container></Container>
    </ContainerWraper>
  );
};

export default HeroSection;
