import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

const CustomBtn = styled(Button)`
    background: #000;
    font-size: ${props=> props.fs ? props.fs : "16px"};
    /* border-radius: 10px; */
    width:  ${props=> props.w ? props.w: "120px"};;
    font-family: ${props=> props.fm ? props.fm : "BadaBoom"};
    margin:  ${props=> props.m ? props.m : "0 5px"};  
    padding:  ${props=> props.p ? props.p : "6px 16px"};
    color:${props => props.c ? props.c : "#000"};
    border: ${props => props.b ? props.b : "none"};
    transition: .5s;
    text-transform: none;
    transform: rotate(0deg);
    position: relative;
    cursor: ${props => props.cur ? props.cur : "pointer"};
    z-index: 1;
    :hover{
      color:${props => props.c ? props.c : "#000"};
      background: ${props => props.bgc ? props.bgc : "#fff"};
      box-shadow: none;
    }
  ::before{
    content: "";
    display: block;
    background: ${props => props.bgc ? props.bgc : "#fff"};
    height: 97%;
    width: 97%;
    position: absolute;
    transform: rotate(1deg);
    top: 0%;
    left: 2%;
    z-index: -1;
    }
    @media(max-width:599px){
      font-size: ${props=> props.fs599 ? props.fs599 : "14px"};
  }

`

const CustomButton = (props) => {
  const {children} = props
  return (
    <CustomBtn variant='filled' {...props}>
        {children}
    </CustomBtn>
  )
}

export default CustomButton