import styled from '@emotion/styled'
import React from 'react'



import cloudFooter from '../../../../assets/cloud-footer.png'
import bkImg from '../../../../assets/memes-bk.png'
import meme11 from '../../../../assets/Images/FVP-meme-1.gif'
import meme2 from '../../../../assets/Images/FVP-meme-2.gif'
import meme3 from '../../../../assets/Images/FVP-meme-3.gif'
import thumbUp from '../../../../assets/thumb-up.png'
import Memestext from '../../../../assets/Images/Memes.png'
import { Container, Grid, Typography } from '@mui/material'
import { Image } from '../../../Styles/style'

const Wrapper = styled.div`
    background-image: url(${bkImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 80px 0px;
    margin-top: -4%;
`
const CloudFooter = styled.img`
    width: 100%;
    height: auto;
    margin-top: -4%;
    position: relative;
    z-index: 9999999;
`

const GIfs = styled.img`
  width: auto;
  height: 200px;

  @media (max-width: 1285px) {
    width: 100%;
    height: auto;
  }
`
const Memes = () => {
  return (
    <>
      <CloudFooter src={cloudFooter} alt='clouds' />
      <Wrapper id="memes">
        <div style={{display:"flex", width:"100%" , justifyContent:"center"}}>

          <Image  data-aos="zoom-in"
        data-aos-duration="2000"  mw="25%" mw599="40%" m="" src={Memestext} alt='Meme' />
        </div>
        <br />
        <br />
        <Container>
          <Grid container>
            <Grid xs={12} sm={6} md={4} style={{padding: '10px 10px', textAlign: 'center'}}>
              <GIfs  data-aos="fade-up" data-aos-duration="2000"  src={meme11} alt="" />
            </Grid>
            <Grid xs={12} sm={6} md={4} style={{padding: '10px 10px', textAlign: 'center'}}>
              <GIfs  data-aos="fade-up" data-aos-duration="2000"  src={meme2} alt="" />
            </Grid>
            <Grid xs={12} sm={12} md={4} style={{padding: '10px 10px', textAlign: 'center'}}>
              <GIfs  data-aos="fade-up" data-aos-duration="2000"  src={meme3} alt="" />
            </Grid>
            
            <Grid data-aos="zoom-in"
        data-aos-duration="2000" xs={12} style={{padding: '10px 10px', textAlign: 'center'}}>
              <img  src={thumbUp} alt="" style={{width: '120px', height: 'auto', marginTop: 40}} />
              <Typography variant='subtitle2' color="#fff" fontFamily="PoppinsSB" align='center'>More memes are coming......</Typography>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    </>
  )
}

export default Memes