import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "HOME",
      link: "#home",
    },
    {
      title: "ABOUT",
      link: "#about",
    },
    {
      title: "TOKENOMICS",
      link: "#tokenomics",
    },
    {
      title: "MEMES",
      link: "#memes",
    },
  ];
  return (
    <>
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </>
  );
};

export default LockerMenu;
