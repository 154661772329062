import styled from '@emotion/styled'
import React from 'react'
import Footer from '../../Footer'
import { Box, Container, Grid, Typography } from '@mui/material'

import cloudFooter from '../../../../assets/cloud-footer.png'
import bkImg from '../../../../assets/footer-bk.jpg'
import fvpText from '../../../../assets/fvp-text.png'
import FooterLogo from '../../../../assets/footer-logo.png'
import FooterFiireLeft from '../../../../assets/Footer-fiire-left.png'
import FooterFiireRright from '../../../../assets/Footer-fiire-right.png'
import telegram from '../../../../assets/Images/telegram.png'
import twitter from '../../../../assets/Images/twitter.png'
import { MenuLink } from '../../LockerMenu/styles'

const Wrapper = styled.div`
    background-color: #FF1315;
    background-image: url(${FooterFiireLeft}), url(${FooterFiireRright}), url(${bkImg});
    background-size: 300px, 300px, cover;
    background-repeat: no-repeat;
    background-position: left bottom, right bottom, top center;
    padding: 80px 0 10px;
    margin-top: -4%;
    @media (max-width: 1300px) {
        background-size: 300px, 300px, cover;

    }
    @media (max-width: 1069px) {
        background-image: url(${bkImg});
        background-position: top center;
        background-size: cover;
    }
`

const CloudFooter = styled.img`
    width: 100%;
    height: auto;
    margin-top: -4%;
    position: relative;
    z-index: 9999999;
`

const FooterLogoStyle = styled.img`
    max-width: 150px;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
`

const FooterFVPStyle = styled(FooterLogoStyle)`
    max-width: 450px;
    margin-bottom: 20px;
`
const TypographyEx = styled(Typography)`
    background-color: #fff;
    width:100%;
    max-width: 480px;
    margin-bottom: 30px;
    padding: 4px 0;
    border: 2px solid #000;
`
const SocialIcon = styled(FooterLogoStyle)`
    max-width: 35px;
    margin: 10px 3px;
`

const FVP = () => {
  return (
    <>
        <CloudFooter src={cloudFooter} alt='clouds'  />
        <Wrapper id="fvp">
            <Container>
                <Grid container >
                    <Grid xs={12} style={{alignItems: 'center',flexDirection: 'column',display: 'flex'}}>
                        <FooterLogoStyle  data-aos="zoom-in"
        data-aos-duration="2000" src={FooterLogo} alt="footer logo"/>
                        <FooterFVPStyle  data-aos="fade-up" data-aos-duration="2000" src={fvpText} alt="Floki vs Pepe logo"/>
                        <TypographyEx data-aos="fade-up" data-aos-duration="2300"  variant='subtitle1' color="#000000" align='center' fontFamily="PoppinsB" fontWeight="bold">CA: 0xc2ab39afab3b761370b6b818c62efdb6d60500f4</TypographyEx>
                        <Box data-aos="fade-up" data-aos-duration="2600"  style={{display: 'flex'}}>
                            <MenuLink href='https://t.me/flokivpepe_coin' target='_blank'>
                                <SocialIcon  src={telegram} alt="footer logo" />
                            </MenuLink>
                            <MenuLink href='https://twitter.com/flokivpepe_coin' target='_blank'  alt="footer logo">
                                <SocialIcon  src={twitter} alt="footer logo" />
                            </MenuLink>
                            {/* <a href='#' target='_blank'>
                                <SocialIcon  src={medium} alt="footer logo" />
                            </a> */}
                        </Box>
                        <br/><br/>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Wrapper>
    </>
  )
}

export default FVP