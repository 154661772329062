import React, { useEffect } from 'react'
import { PageWrapper } from '../../Styles/style'
import HeroSection from '../../Components/Sections/Header'
import FVP from '../../Components/Sections/FVP'
import Memes from '../../Components/Sections/Memes'
import Tokenomics from '../../Components/Sections/Tokenomics'
import Aboutus from '../../Components/Sections/About'
import AOS from 'aos';
import 'aos/dist/aos.css';
const MainPage = (props) => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <PageWrapper>
        <HeroSection/>
        <Aboutus />
        <Tokenomics />
        <Memes />
        <FVP />
    </PageWrapper>
  )
}

export default MainPage