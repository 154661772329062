
import React from "react";
import { Container, Grid } from "@mui/material";
import { Div, Image } from "../../../Styles/style";
import metamask from "../../../../assets/metamask.png";
import trustwallet from "../../../../assets/trustwallet.png";
import dextools from "../../../../assets/dextools.png";
import dexscrener from "../../../../assets/dexscrener.png";
import uniswap from "../../../../assets/uniswap.png";
import etherscan from "../../../../assets/etherscan.png";
import aboutbg from "../../../../assets/about-bg.jpg";
import Paragraph from "../../Paragraph";
import cloudFooter from '../../../../assets/cloud-footer.png'

import Abouttext from '../../../../assets/Images/About.png'
import styled from '@emotion/styled'
import CustomButton from "../../CustomBtn";
import { MenuLink } from "../../LockerMenu/styles";

const ContinerWraper = styled(Div)`
  background-image: url(${aboutbg});
  background-repeat: no-repeat;
  background-size: cover;
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 0 120px;
  margin-top: -4%;
`;

const ContinerWraper1 = styled(Container)`
  width: 100%;
  padding: 100px 0;
  margin-top: -4%;
`;
const ImageGrid = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 899px) {
    flex-direction: column;
  }
`;
const CloudFooter = styled.img`
    width: 100%;
    height: auto;
    margin-top: -4%;
    position: relative;
    z-index: 9999999;
`
const StatsSection = () => {
  return (
    <div>
      <CloudFooter src={cloudFooter} alt='clouds' />
      <ContinerWraper1>
        <Grid container>
          <ImageGrid item xs={12} md={12}>
            <Div
              fd899="column"
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <Image mw="120px" src={metamask} />
              <Image mw="120px" src={trustwallet} />
              <Image mw="120px" src={dextools} />
              <Image mw="120px" src={dexscrener} />
              <Image mw="120px" src={uniswap} />
              <Image mw="120px" src={etherscan} />
            </Div>
          </ImageGrid>
        </Grid>
      </ContinerWraper1>
      
      <CloudFooter src={cloudFooter} alt='clouds' />
      <ContinerWraper
       id="about"
        sx={{
          mt: "50px !important",
          pt: "50px !important",
          pb: "50px !important",
        }}
      >
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Image  data-aos="zoom-in"
        data-aos-duration="1500" mw="40%" mw599="60%" m="" src={Abouttext} alt='ABOUT $FLOPE' />
            
          </Grid>
          <Grid
            item
            xs={11}
            sm={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paragraph data-aos="fade-up" data-aos-duration="1500" fs="14px" p="30px 0" style={{width:'80% !important'}}>
            "Dive into the exciting realm of cryptocurrencies with $FLOPE, an innovative project that brings together the iconic Floki and the timeless internet meme sensation, Pepe. 
              <br></br>
              <br></br>
              $FLOPE is more than just a token; it's a thrilling fusion of pop culture and blockchain technology. With a tokenomics structure that ensures fairness and equality, $FLOPE provides enthusiasts with a chance to join the ultimate meme battle. Purchase your $FLOPE tokens on today and stay informed about the token's dynamic journey through our active team members and community on Telegram. Join us in witnessing the clash of these iconic characters in the world of cryptocurrencies – an event that's set to redefine the landscape of meme tokens!
            </Paragraph>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MenuLink href="https://t.me/flokivpepe_coin" target="blank">
            <CustomButton
            fm="PoppinsB"
              w="270px"
              bgc="#FFCD0C"
              c="#000"
              hbgc="#FFCD0C"
              hc="#000"
            >
              Join FLOPE Community
            </CustomButton></MenuLink>
          </Grid>
        </Grid>
      </ContinerWraper> 
    </div>
  );
};

export default StatsSection;
