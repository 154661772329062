import styled from "@emotion/styled";
import {  Grid } from "@mui/material";

const PageWrapper = styled.div`
    background-color: #000000;
    min-height: 100vh;
`
const Image = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "30px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  position: relative;
    z-index: 999;
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "30%")};
  bottom: ${(props) => (props.b)};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,-30%)")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (min-width: 1399px) {
    max-width: 100%;
  }
  @media (max-width: 899px) {
    max-width: ${(props) => props.mw899};
    left: ${(props) => props.l899};
    top: ${(props) => props.t899};

    padding: ${(props) => (props.m ? props.m : "0 0px 0 0")};
    opacity: ${props => props.op};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
   @media(max-width:899px){
    flex-direction: ${props => props.fd899};
    row-gap: ${props => props.fd899 ? "20px" : ""};
  }
`;
const GridWraper = styled(Grid)`
  width: 100%;
  min-height: calc(100vh - 90px);
  position: relative;
  display: flex;
  align-content: space-between;
  justify-content: center;
`;

const GradiantDiv = styled.div`
  display: flex;
  background-image: url(${props => props.bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  position: relative;
  border-radius: 20px;
  row-gap: 10px;
  padding: ${(props) => (props.p ? props.p : "20px 30px 20px 20px")};
  margin: 20px 0;
  width: ${(props) => (props.w ? props.w : "100%")};
  height: ${(props) => (props.h ? props.h : "100%")};
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: ${(props) => props.theme.bgGradient};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: .5;
  }
`;

const SndGradiantDiv = styled.div`
  display: flex;
  
  flex-direction: column;
  justify-content: start;
  align-items: start;
  position: relative;
  border-radius: 20px;
  row-gap: 10px;
  padding: ${(props) => (props.p ? props.p : "20px 30px 20px 20px")};
  margin: 20px 0;
  width: ${(props) => (props.w ? props.w : "100%")};
  height: ${(props) => (props.h ? props.h : "100%")};
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: url(${props => props.bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: .5;
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: ${(props) => props.theme.bgGradient};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: .6;
  }
`;





export { AbsoluiteImage,Image , PageWrapper , Div, GridWraper, GradiantDiv ,SndGradiantDiv }